<template>
	<v-data-table
		ref="dataTable"
		:loading="loading"
		:items="items"
		:headers="headers"
		dense
		disable-pagination
		hide-default-footer
		multi-sort
	>
		<template #item.link="{item}">
			<imot-details-link
				target-blank
				:utility="utility"
				:session="session"
				:station="item.station_id"
				:imot="item.imot_id"
			/>
		</template>
		<template #footer>
			<v-divider/>
			<div class="text-right mt-4">
				<export-csv color="primary" :headers="headers" :items="exportItems">
					<v-icon left>mdi-export</v-icon>
					CSV
				</export-csv>
			</div>
		</template>
	</v-data-table>
</template>

<script>
import IModelMixin from "@/ittijs/IModelMixin";
import ExportCsv from "@/ittijs/components/export-csv";
import MountedMixin from "@/ittijs/MountedMixin";
import ImotDetailsLink from "@/ittijs/components/ImotDetailsLink.vue";

export default {
	components: {ImotDetailsLink, ExportCsv},
	mixins: [
		IModelMixin,
		MountedMixin,
	],
	props: [
		'utility',
		'session',
	],
	data(){
		return {
			loading: false,
			items: [],
			headers: [
				{text: "Станция", value: "station_N"},
				{text: "Имот", value: "imot_N"},
				{text: "Адрес", value: "address"},
				{text: "Номер", value: "device_N"},
				{text: "Тип", value: "device_type_code"},
				{text: "Стая", value: "room"},
				{text: "Старо", value: "value_old"},
				{text: "Ново", value: "value_new"},
				{text: "Разлика", value: "value_diff"},
				{text: "Старо 2", value: "value_old2"},
				{text: "Ново 2", value: "value_new2"},
				{text: "Разлика 2", value: "value_diff2"},
				{text: "", value: "link", width: "1%", csv: false},
			],
		}
	},
	computed: {
		exportItems(){
			if (!this.mounted) return [];
			return this.$refs.dataTable && this.$refs.dataTable.internalCurrentItems || [];
		},
	},
	mounted() {
		this.load();
	},
	methods: {
		async load(){
			if (this.loading) return;
			this.loading = true;
			this.items = [];
			this.items = await this.imodel.negValList(this.session);
			this.loading = false;
		},
	},
}
</script>