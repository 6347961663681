<template>
	<div>
		<v-data-table
			ref="dataTable"
			:loading="loading"
			:items="items"
			:headers="headers"
			dense
			disable-pagination
			hide-default-footer
			multi-sort
		>
			<template #item.link="{item}">
				<imot-details-link
					target-blank
					:utility="utility"
					:session="session"
					:station="item.dd.station_id"
					:imot="item.dd.imot_id"
				/>
			</template>
		</v-data-table>
		<div class="text-right mt-4">
			<export-csv color="primary" :headers="headers" :items="exportItems">
				<v-icon left>mdi-export</v-icon>
				CSV
			</export-csv>
		</div>
	</div>
</template>

<script>
import ExportCsv from "@/ittijs/components/export-csv";
import MountedMixin from "@/ittijs/MountedMixin";
import {SamootchetType, optionsSamootchet} from "@/ittijs/Nomenclatures";
import ImotDetailsLink from "@/ittijs/components/ImotDetailsLink.vue";

export default {
	components: {ImotDetailsLink, ExportCsv},
	mixins: [MountedMixin],
	props: [
		'utility',
		'session',
		'items',
	],
	data(){
		return {
			loading: false,
			headers: [
				{text: "Станция No",  value: "station.station_N"},
				{text: "Имот No",     value: "imot.imot_N"},
				{text: "Адрес",       value: "imot.address"},
				{text: "Уред No",     value: "dd.device_N"},
				{text: "Показание",   value: "dd.value_new"},
				{text: "Радио",       value: "radio.TekPok"},
				{text: optionsSamootchet.find(o=>o.value===SamootchetType.BELEJKA).text, value: "self_1.value"},
				{text: optionsSamootchet.find(o=>o.value===SamootchetType.TELEFON).text, value: "self_2.value"},
				{text: optionsSamootchet.find(o=>o.value===SamootchetType.WEB).text,     value: "self_3.value"},
				{text: optionsSamootchet.find(o=>o.value===SamootchetType.EMAIL).text,   value: "self_4.value"},
				{text: "", value: "link", width: "1%", csv: false},
			],
		}
	},
	computed: {
		exportItems(){
			if (!this.mounted) return [];
			return this.$refs.dataTable && this.$refs.dataTable.internalCurrentItems || [];
		},
	},
}
</script>