<template>
	<panel-base :checked="data!==null" :ok="data && data.length===0" :loading="loading" @start="start" @stop="stop">
		<template #heading>
			Имоти с изтекъл договор за отчет на студена вода
			<span v-if="data">
				({{ data.length }})
			</span>
		</template>
		<template #content v-if="data">
			<imot-list
				:utility="utility"
				:session="session"
				:items="data"
			/>
		</template>
	</panel-base>
</template>
<script>
import expansionPanelShort from "@/ittijs/ExpansionPanelShort";
import PanelBase from "@/views/report/checks/panelBase";
import ImotList from "@/views/report/checks/imot-list";
import checkMixin from "@/views/report/checks/checkMixin";

export default {
	components: {ImotList, PanelBase},
	mixins: [
		expansionPanelShort,
		checkMixin(
			(self, getController) => self.imodel.docExpiredColdWater(self.session, getController)
		),
	],
	props: [
		'utility',
		'session',
		'imodel',
	],
	watch: {
		utility(){ this.reset() },
		session(){ this.reset() },
	},
}
</script>