<template>
	<div>
		<v-data-table
			ref="dataTable"
			:loading="loading"
			:items="items"
			:headers="headers"
			dense
			multi-sort
		>
			<template #item.is_radio="{value}">
				<v-icon color="green" v-if="value=='1'" style="vertical-align: bottom;" title="Радио">mdi-wifi</v-icon>
				<v-icon color="grey" v-else style="vertical-align: bottom;" title="Радио">mdi-wifi-off</v-icon>
			</template>
			<template #item.link="{item}">
				<imot-details-link
					target-blank
					:utility="utility"
					:session="session"
					:station="item.station_id"
					:imot="item.imot_id"
				/>
			</template>
		</v-data-table>
		<v-divider/>
		<div class="text-right mt-4">
			<export-csv color="primary" :headers="headers" :items="items">
				<v-icon left>mdi-export</v-icon>
				CSV
			</export-csv>
		</div>
	</div>
</template>

<script>
import ExportCsv from "@/ittijs/components/export-csv";
import ImotDetailsLink from "@/ittijs/components/ImotDetailsLink.vue";
export default {
	components: {ImotDetailsLink, ExportCsv},
	props: {
		utility: String,
		session: String,
		callback: Function,
	},
	data(){
		return {
			loading: false,
			items: [],
			headers: [
				{text: "Станция", value: "station_N"},
				{text: "Имот", value: "imot_N"},
				{text: "Адрес", value: "address"},
				{text: "", value: "link", width: "1%", csv: false},
			],
		}
	},
	mounted() {
		this.load();
	},
	methods: {
		async load(){
			if (this.loading) return;
			this.loading = true;
			this.items = [];
			this.items = await this.callback();
			this.loading = false;
		},
	},
}
</script>