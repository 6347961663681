<template>
	<panel-base :checked="data!==null" :ok="data===0" :loading="loading" @start="start" @stop="stop">
		<template #heading>
			Без консумация при &gt;=3 работни дни
			<span v-if="data!==null">
				({{ data }})
			</span>
		</template>
		<template #content v-if="data!==null">
			<rest-imot-list
				:utility="utility"
				:session="session"
				:callback="()=>imodel.yesWorkDaysWithoutConsList(session)"
			></rest-imot-list>
		</template>
	</panel-base>
</template>
<script>
import expansionPanelShort from "@/ittijs/ExpansionPanelShort";
import PanelBase from "@/views/report/checks/panelBase";
import RestImotList from "@/views/report/checks/rest-imot-list";
import checkMixin from "@/views/report/checks/checkMixin";

export default {
	components: {RestImotList, PanelBase},
	mixins: [
		expansionPanelShort,
		checkMixin(
			(self, getController) => self.imodel.yesWorkDaysWithoutCons(self.session, getController)
		),
	],
	props: [
		'utility',
		'session',
		'imodel',
	],
	watch: {
		utility(){ this.reset() },
		session(){ this.reset() },
	},
}
</script>