<template>
	<panel-base :checked="data!==null" :ok="data && Object.keys(data).length===0" :loading="loading" @start="start" @stop="stop">
		<template #heading>
			Дублирани номера на у-ва
			<span v-if="data">
				({{ Object.keys(data).length }} номера,
				общо {{ objSum(data) }} у-ва)
			</span>
		</template>
		<template #content v-if="data">
			<ep-list>
				<ep-panel v-for="(count, deviceN) in data" :key="deviceN">
					<ep-header>{{count}} у-ва с номер {{deviceN}}: </ep-header>
					<ep-content>
						<dup-dev-list
							:number="deviceN"
							:session="session"
							:imodel="imodel"
							:utility="utility"
						></dup-dev-list>
					</ep-content>
				</ep-panel>
			</ep-list>
		</template>
	</panel-base>
</template>
<script>
import expansionPanelShort from "@/ittijs/ExpansionPanelShort";
import DupDevList from "@/views/report/checks/components/dupDevList";
import PanelBase from "@/views/report/checks/panelBase";
import checkMixin from "@/views/report/checks/checkMixin";

export default {
	components: {PanelBase, DupDevList},
	mixins: [
		expansionPanelShort,
		checkMixin(
			(self, getController) => self.imodel.dupDev(self.session, getController)
		),
	],
	props: [
		'utility',
		'session',
		'imodel',
	],
	watch: {
		utility(){ this.reset() },
		session(){ this.reset() },
	},
	methods: {
		objSum(obj){
			return Object.values(obj).reduce((sum,v)=>sum+parseInt(v), 0);
		},
	},
}
</script>