<template>
	<v-container fluid>
		<please-select-utility>
			<v-row>
				<v-col cols="12" class="d-flex">
					<session-select
						:utility="utility"
						v-model="selectedSession"
						:session.sync="sessionRow"
						:readonly="loading"
						dense outlined hide-details single-line label="Сесия"
						style="max-width: 400px"
						class="mr-2"
					/>
				</v-col>
			</v-row>
			<v-row v-if="selectedSession">
				<v-col>
					<v-btn small color="primary" v-if="!loadingAll" @click="start" width="120">Пусни всички</v-btn>
					<v-btn small color="error" v-else @click="stop" width="120">
						<v-progress-circular indeterminate size="20" width="2" class="mr-2" />
						Стоп
					</v-btn>
				</v-col>
			</v-row>
			<v-row v-if="selectedSession">
				<v-col>
					<ep-list v-model="expansion">
						<dup-dev
							:utility="utility"
							:session="session"
							:imodel="imodel"
							ref="dupDev"
							@beforeload="stop"
						/>
						<neg-val
							:utility="utility"
							:session="session"
							:imodel="imodel"
							ref="negVal"
							@beforeload="stop"
						/>
						<neg-cons
							v-if="isUtilityTEC"
							:utility="utility"
							:session="session"
							:imodel="imodel"
							ref="negCons"
							@beforeload="stop"
						/>
						<no-work-days-with-cons
							v-if="isUtilityTEC"
							:utility="utility"
							:session="session"
							:imodel="imodel"
							ref="noWorkDaysWithCons"
							@beforeload="stop"
						/>
						<yes-work-days-without-cons
							v-if="isUtilityTEC"
							:utility="utility"
							:session="session"
							:imodel="imodel"
							ref="yesWorkDaysWithoutCons"
							@beforeload="stop"
						/>
						<toplomer-plus-radiator
							v-if="isUtilityTEC"
							:utility="utility"
							:session="session"
							:imodel="imodel"
							ref="toplomerPlusRadiator"
							@beforeload="stop"
						/>
						<progn-no-heating
							v-if="isUtilityTEC"
							:utility="utility"
							:session="session"
							:imodel="imodel"
							ref="prognNoHeating"
							@beforeload="stop"
						/>
						<radio-samootchet-mismatch
							:utility="utility"
							:session="session"
							:imodel="imodel"
							ref="radioSamootchetMismatch"
							@beforeload="stop"
						/>
						<doc-voda-with-value
							v-if="isUtilityTEC"
							:utility="utility"
							:session="session"
							:imodel="imodel"
							ref="docVodaWithValue"
							@beforeload="stop"
						/>
						<doc-heat-with-value
							v-if="isUtilityTEC"
							:utility="utility"
							:session="session"
							:imodel="imodel"
							ref="docHeatWithValue"
							@beforeload="stop"
						/>
						<doc-expired-cold-water
							v-if="isUtilityVIK"
							:utility="utility"
							:session="session"
							:imodel="imodel"
							ref="docExpiredColdWater"
							@beforeload="stop"
						/>
						<voda-duplicate-dogovor
							v-if="isUtilityVIK"
							:utility="utility"
							:session="session"
							:imodel="imodel"
							ref="vodaDuplicateDogovor"
							@beforeload="stop"
						/>
						<voda-orphan-dogovor
							v-if="isUtilityVIK"
							:utility="utility"
							:session="session"
							:imodel="imodel"
							ref="vodaOrphanDogovor"
							@beforeload="stop"
						/>
						<voda-orphan-vodomer
							v-if="isUtilityVIK"
							:utility="utility"
							:session="session"
							:imodel="imodel"
							ref="vodaOrphanVodomer"
							@beforeload="stop"
						/>
						<wpl-order
							v-if="isUtilityTEC"
							:utility="utility"
							:session="session"
							:imodel="imodel"
							ref="wplOrder"
							@beforeload="stop"
						/>
						<percent-sgr
							v-if="isUtilityTEC"
							:utility="utility"
							:session="session"
							:imodel="imodel"
							ref="percentSgr"
							@beforeload="stop"
						/>
						<sum-imoti-obsht-toplomer
							v-if="isUtilityTEC"
							:utility="utility"
							:session="session"
							:imodel="imodel"
							ref="sumImotiObshtToplomer"
							@beforeload="stop"
						/>
						<yes-work-days-hw-no-m3
							v-if="isUtilityTEC"
							:utility="utility"
							:session="session"
							:imodel="imodel"
							ref="yesWorkDaysHwNoM3"
							@beforeload="stop"
						/>
						<warning-k-bgv-kor-m3
							v-if="isUtilityTEC"
							:utility="utility"
							:session="session"
							:imodel="imodel"
							ref="warningKBgvKorM3"
							@beforeload="stop"
						/>
						<heating-no-progn
							v-if="isUtilityTEC"
							:utility="utility"
							:session="session"
							:imodel="imodel"
							ref="heatingNoProgn"
							@beforeload="stop"
						/>
						<voda-no-progn
							v-if="isUtilityTEC"
							:utility="utility"
							:session="session"
							:imodel="imodel"
							ref="vodaNoProgn"
							@beforeload="stop"
						/>
						<progn-dev-diff-heating
							v-if="isUtilityTEC"
							:utility="utility"
							:session="session"
							:imodel="imodel"
							ref="prognDevDiffHeating"
							@beforeload="stop"
						/>
						<progn-dev-diff-voda
							v-if="isUtilityTEC"
							:utility="utility"
							:session="session"
							:imodel="imodel"
							ref="prognDevDiffVoda"
							@beforeload="stop"
						/>
						<broi-lica-samootchet
							v-if="isUtilityTEC"
							:utility="utility"
							:session="session"
							:imodel="imodel"
							ref="broiLicaSamootchet"
							@beforeload="stop"
						/>
					</ep-list>
				</v-col>
			</v-row>
		</please-select-utility>
	</v-container>
</template>

<script>
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import RouteSyncMixin from "@/ittijs/RouteSyncMixin";
import PleaseSelectUtility from "@/ittijs/components/PleaseSelectUtility";
import SessionSelect from "@/views/sessions/SessionSelect";
import Model from "./Model";
import expansionPanelShort from "@/ittijs/ExpansionPanelShort";
import RadioSamootchetMismatch from "@/views/report/checks/components/radioSamootchetMismatch";
import DupDev from "@/views/report/checks/components/dupDev";
import NegVal from "@/views/report/checks/components/negVal";
import DocVodaWithValue from "@/views/report/checks/components/docVodaWithValue";
import DocHeatWithValue from "@/views/report/checks/components/docHeatWithValue";
import NoWorkDaysWithCons from "@/views/report/checks/components/noWorkDaysWithCons";
import YesWorkDaysWithoutCons
	from "@/views/report/checks/components/yesWorkDaysWithoutCons";
import ToplomerPlusRadiator from "@/views/report/checks/components/toplomerPlusRadiator";
import PrognNoHeating from "@/views/report/checks/components/prognNoHeating";
import WplOrder from "@/views/report/checks/components/wplOrder";
import PercentSgr from "@/views/report/checks/components/percentSgr";
import SumImotiObshtToplomer from "@/views/report/checks/components/sumImotiObshtToplomer";
import YesWorkDaysHwNoM3
	from "@/views/report/checks/components/yesWorkDaysHwNoM3";
import HeatingNoProgn from "@/views/report/checks/components/heatingNoProgn";
import VodaNoProgn from "@/views/report/checks/components/vodaNoProgn";
import NegCons from "@/views/report/checks/components/negCons";
import DocExpiredColdWater
	from "@/views/report/checks/components/docExpiredColdWater";
import PrognDevDiffHeating
	from "@/views/report/checks/components/prognDevDiffHeating";
import PrognDevDiffVoda
	from "@/views/report/checks/components/prognDevDiffVoda";
import BroiLicaSamootchet
	from "@/views/report/checks/components/broiLicaSamootchet.vue";
import WarningKBgvKorM3
	from "@/views/report/checks/components/warningKBgvKorM3.vue";
import VodaDuplicateDogovor
	from "@/views/report/checks/components/vodaDuplicateDogovor.vue";
import VodaOrphanDogovor
	from "@/views/report/checks/components/vodaOrphanDogovor.vue";
import VodaOrphanVodomer
	from "@/views/report/checks/components/vodaOrphanVodomer.vue";

const model = new Model;

const queueRefs = [
	'dupDev',
	'negVal',
	'negCons',
	'noWorkDaysWithCons',
	'yesWorkDaysWithoutCons',
	'toplomerPlusRadiator',
	'prognNoHeating',
	'radioSamootchetMismatch',
	'docVodaWithValue',
	'docHeatWithValue',
	'docExpiredColdWater',
	'wplOrder',
	'percentSgr',
	'sumImotiObshtToplomer',
	'yesWorkDaysHwNoM3',
	'heatingNoProgn',
	'vodaNoProgn',
	'vodaDuplicateDogovor',
	'vodaOrphanDogovor',
	'vodaOrphanVodomer',
	'prognDevDiffHeating',
	'prognDevDiffVoda',
	'broiLicaSamootchet',
	'warningKBgvKorM3',
];

export default {
	components: {
		WarningKBgvKorM3,
		BroiLicaSamootchet,
		PrognDevDiffVoda,
		PrognDevDiffHeating,
		DocExpiredColdWater,
		NegCons,
		HeatingNoProgn,
		VodaOrphanVodomer,
		VodaOrphanDogovor,
		VodaDuplicateDogovor,
		VodaNoProgn,
		YesWorkDaysHwNoM3,
		SumImotiObshtToplomer,
		PercentSgr,
		WplOrder,
		PrognNoHeating,
		ToplomerPlusRadiator,
		YesWorkDaysWithoutCons,
		NoWorkDaysWithCons,
		DocHeatWithValue,
		DocVodaWithValue,
		NegVal,
		DupDev,
		RadioSamootchetMismatch,
		// DupDevNumList,
		SessionSelect,
		// StationSelect,
		PleaseSelectUtility,
	},
	mixins: [
		expansionPanelShort,
		SelectedUtilityMixin,
		RouteSyncMixin({
			utility:'selectedUtility',
			session:'selectedSession',
			// station:'selectedStation',
		}),
	],
	props: [
		'utility',
		'session',
		// 'station',
	],
	data(){
		return {
			selectedSession: null,
			// selectedStation: null,
			loading: false,
			loadingAll: false,
			sessionRow: {},
			expansion: null,
			imodel: model,
			currentRunning: null,
		}
	},
	computed: {
		isUtilityVIK(){
			return this.selectedUtilityData && this.selectedUtilityData.utility_type === 'VIK';
		},
		isUtilityTEC(){
			return this.selectedUtilityData && this.selectedUtilityData.utility_type === 'TEC';
		},
	},
	watch: {
		utility(){
			this.selectedSession = null;
			// this.selectedStation = null;
			this.expansion = null;
		},
		session(){
			this.expansion = null;
		},
	},
	methods: {
		async start(){
			if (this.loadingAll) return;
			this.loadingAll = true;
			try {
				for (const ref of queueRefs) {
					this.currentRunning = this.$refs[ref];
					this.$refs[ref]
					&& this.$refs[ref].start
					&& await this.$refs[ref].start();
					this.currentRunning = null;
				}
			}
			finally {
				this.loadingAll = false;
			}
		},
		stop(){
			if (!this.loadingAll) return;
			this.loadingAll = false;
			if (this.currentRunning) {
				this.currentRunning.stop();
			}
		},
	},
}
</script>
