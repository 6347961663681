import {ITTIModelBase} from '@/ittijs/ITTIModel';
export default class extends ITTIModelBase {
	constructor() {
		super('Checks');
	}
	dupDev(session: number, getController: Function|null = null){
		return this.fetch('dupDev', {session}, null, getController);
	}
	dupDevInfo(session: number, device: string, getController: Function|null = null){
		return this.fetch('dupDevInfo', {session, device}, null, getController);
	}
	invalidBRL(session: number, getController: Function|null = null){
		return this.fetch('invalidBRL', {session}, null, getController);
	}
	negVal(session: number, getController: Function|null = null){
		return this.fetch('negVal', {session}, null, getController);
	}
	negValList(session: number, getController: Function|null = null){
		return this.fetch('negValList', {session}, null, getController);
	}
	negCons(session: number, getController: Function|null = null){
		return this.fetch('negCons', {session}, null, getController);
	}
	negConsList(session: number, getController: Function|null = null){
		return this.fetch('negConsList', {session}, null, getController);
	}
	noWorkDaysWithCons(session: number, getController: Function|null = null){
		return this.fetch('noWorkDaysWithCons', {session}, null, getController);
	}
	noWorkDaysWithConsList(session: number, getController: Function|null = null){
		return this.fetch('noWorkDaysWithConsList', {session}, null, getController);
	}
	yesWorkDaysWithoutCons(session: number, getController: Function|null = null){
		return this.fetch('yesWorkDaysWithoutCons', {session}, null, getController);
	}
	yesWorkDaysWithoutConsList(session: number, getController: Function|null = null){
		return this.fetch('yesWorkDaysWithoutConsList', {session}, null, getController);
	}
	toplomerPlusRadiator(session: number, getController: Function|null = null){
		return this.fetch('toplomerPlusRadiator', {session}, null, getController);
	}
	toplomerPlusRadiatorList(session: number, getController: Function|null = null){
		return this.fetch('toplomerPlusRadiatorList', {session}, null, getController);
	}
	prognNoHeating(session: number, getController: Function|null = null){
		return this.fetch('prognNoHeating', {session}, null, getController);
	}
	prognNoHeatingList(session: number, getController: Function|null = null){
		return this.fetch('prognNoHeatingList', {session}, null, getController);
	}
	docVodaWithValue(session: number, getController: Function|null = null){
		return this.fetch('docVodaWithValue', {session}, null, getController);
	}
	docHeatWithValue(session: number, getController: Function|null = null){
		return this.fetch('docHeatWithValue', {session}, null, getController);
	}
	radioSamootchetMismatch(session: number, getController: Function|null = null){
		return this.fetch('radioSamootchetMismatch', {session}, null, getController);
	}
	wplOrder(session: number, getController: Function|null = null){
		return this.fetch('wplOrder', {session}, null, getController);
	}
	percentSgr(session: number, getController: Function|null = null){
		return this.fetch('percentSgr', {session}, null, getController);
	}
	sumImotiObshtToplomer(session: number, getController: Function|null = null){
		return this.fetch('sumImotiObshtToplomer', {session}, null, getController);
	}
	yesWorkDaysHwNoM3(session: number, getController: Function|null = null){
		return this.fetch('yesWorkDaysHwNoM3', {session}, null, getController);
	}
	heatingNoProgn(session: number, getController: Function|null = null){
		return this.fetch('heatingNoProgn', {session}, null, getController);
	}
	vodaNoProgn(session: number, getController: Function|null = null){
		return this.fetch('vodaNoProgn', {session}, null, getController);
	}
	vodaDuplicateDogovor(session: number, getController: Function|null = null){
		return this.fetch('vodaDuplicateDogovor', {session}, null, getController);
	}
	vodaOrphanDogovor(session: number, getController: Function|null = null){
		return this.fetch('vodaOrphanDogovor', {session}, null, getController);
	}
	vodaOrphanVodomer(session: number, getController: Function|null = null){
		return this.fetch('vodaOrphanVodomer', {session}, null, getController);
	}
	docExpiredColdWater(session: number, getController: Function|null = null){
		return this.fetch('docExpiredColdWater', {session}, null, getController);
	}
	prognDevDiffHeating(session: number, getController: Function|null = null){
		return this.fetch('prognDevDiffHeating', {session}, null, getController);
	}
	prognDevDiffVoda(session: number, getController: Function|null = null){
		return this.fetch('prognDevDiffVoda', {session}, null, getController);
	}
	broiLicaSamootchet(session: number, getController: Function|null = null){
		return this.fetch('broiLicaSamootchet', {session}, null, getController);
	}
	warningKBgvKorM3(session: number, getController: Function|null = null){
		return this.fetch('warningKBgvKorM3', {session}, null, getController);
	}
}